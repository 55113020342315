import React from "react";

export const Footer = (props) => {
  return (
    <footer class="footer-area footer-eleven">
      <div class="footer-top">
        <div class="container">
          <div class="inner-content">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="footer-widget f-about">
                  <div class="logo">
                    <a href="index.html">
                      <img style ={{width: 180 + 'px', height: 50 + 'px'}} src="assets/images/GHUSOF-WEB.png" alt="#" class="img-fluid" />
                    </a>
                  </div>
                  <p>
                    Making the world a better place through constructing elegant
                    hierarchies.
                  </p>
                  <p class="copyright-text">
                    <span>© 2024 Ghusof Solutions</span>Designed and Developed by &nbsp;
                    <a href="javascript:void(0)" rel="nofollow"> Ghusof Solutions </a>
                  </p>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12">
                <div class="footer-widget f-link">
                  <h5>Solutions</h5>
                  <ul>
                    <li><a href="javascript:void(0)">Marketing</a></li>
                    <li><a href="javascript:void(0)">Analytics</a></li>
                    <li><a href="javascript:void(0)">Commerce</a></li>
                    <li><a href="javascript:void(0)">Insights</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12">
                <div class="footer-widget f-link">
                  <h5>Support</h5>
                  <ul>
                    <li><a href="javascript:void(0)">Pricing</a></li>
                    <li><a href="javascript:void(0)">Documentation</a></li>
                    <li><a href="javascript:void(0)">Guides</a></li>
                    <li><a href="javascript:void(0)">API Status</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="footer-widget newsletter">
                  <h5>Subscribe</h5>
                  <p>Subscribe to our newsletter for the latest updates</p>
                  <form action="#" method="get" target="_blank" class="newsletter-form">
                    <input name="EMAIL" placeholder="Email address" required="required" type="email" />
                    <div class="button">
                      <button class="sub-btn">
                        <i class="lni lni-envelope"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>


  );
};