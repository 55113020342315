import React from "react";

export const Navigation = (props) => {
  return (
    <section class="navbar-area navbar-nine">
    <div class="container">
    <div class="row">
        <div class="col-lg-12">
        <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="index.html">
            <img  style ={{width: 180 + 'px', height: 50 + 'px'}} src="assets/images/GHUSOF-NEGATIVO-SIN-FONDO.png" alt="Logo" />
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNine"
            aria-controls="navbarNine" aria-expanded="false" aria-label="Toggle navigation">
            <span class="toggler-icon"></span>
            <span class="toggler-icon"></span>
            <span class="toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse sub-menu-bar" id="navbarNine">
            <ul class="navbar-nav me-auto">
                <li class="nav-item">
                <a class="page-scroll active" href="#hero-area">Home</a>
                </li>
                <li class="nav-item">
                <a class="page-scroll" href="#about-us">About us</a>
                </li>
                <li class="nav-item">
                <a class="page-scroll" href="#services">Services</a>
                </li>
                <li class="nav-item">
                <a class="page-scroll" href="#contact">Contact</a>
                </li>
            </ul>
            </div>
        </nav>

        </div>
    </div>

    </div>

    </section>


  );
};