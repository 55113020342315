import React from "react";

export const AboutUs = (props) => {
  return (
    <section id="about-us" class="pricing-area pricing-fourteen">
    <div class="section-title-five">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="content">
              <h6>Pricing</h6>
              <h2 class="fw-bold">Pricing & Plans</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="pricing-style-fourteen">
            <div class="table-head">
              <h6 class="title">Starter</h6>
                <p>Lorem Ipsum is simply dummy text of the printing and industry.</p>
                <div class="price">
                  <h2 class="amount">
                    <span class="currency">$</span>0<span class="duration">/mo </span>
                  </h2>
                </div>
            </div>

            <div class="light-rounded-buttons">
              <a href="javascript:void(0)" class="btn primary-btn-outline">
                Start free trial
              </a>
            </div>

            <div class="table-content">
              <ul class="table-list">
                <li> <i class="lni lni-checkmark-circle"></i> Cras justo odio.</li>
                <li> <i class="lni lni-checkmark-circle"></i> Dapibus ac facilisis in.</li>
                <li> <i class="lni lni-checkmark-circle deactive"></i> Morbi leo risus.</li>
                <li> <i class="lni lni-checkmark-circle deactive"></i> Excepteur sint occaecat velit.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="pricing-style-fourteen middle">
            <div class="table-head">
              <h6 class="title">Exclusive</h6>
                <p>Lorem Ipsum is simply dummy text of the printing and industry.</p>
                <div class="price">
                  <h2 class="amount">
                    <span class="currency">$</span>99<span class="duration">/mo </span>
                  </h2>
                </div>
            </div>

            <div class="light-rounded-buttons">
              <a href="javascript:void(0)" class="btn primary-btn">
                Start free trial
              </a>
            </div>

            <div class="table-content">
              <ul class="table-list">
                <li> <i class="lni lni-checkmark-circle"></i> Cras justo odio.</li>
                <li> <i class="lni lni-checkmark-circle"></i> Dapibus ac facilisis in.</li>
                <li> <i class="lni lni-checkmark-circle"></i> Morbi leo risus.</li>
                <li> <i class="lni lni-checkmark-circle deactive"></i> Excepteur sint occaecat velit.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="pricing-style-fourteen">
            <div class="table-head">
              <h6 class="title">Premium</h6>
                <p>Lorem Ipsum is simply dummy text of the printing and industry.</p>
                <div class="price">
                  <h2 class="amount">
                    <span class="currency">$</span>150<span class="duration">/mo </span>
                  </h2>
                </div>
            </div>

            <div class="light-rounded-buttons">
              <a href="javascript:void(0)" class="btn primary-btn-outline">
                Start free trial
              </a>
            </div>

            <div class="table-content">
              <ul class="table-list">
                <li> <i class="lni lni-checkmark-circle"></i> Cras justo odio.</li>
                <li> <i class="lni lni-checkmark-circle"></i> Dapibus ac facilisis in.</li>
                <li> <i class="lni lni-checkmark-circle"></i> Morbi leo risus.</li>
                <li> <i class="lni lni-checkmark-circle"></i> Excepteur sint occaecat velit.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



  );
};