import React from "react";

export const Service = (props) => {
  return (
    
    <section id="services" class="services-area services-eight">
    <div class="section-title-five">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="content">
              <h6>Services</h6>
              <h2 class="fw-bold">Our Best Services</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="service-icon">
              <i class="lni lni-capsule"></i>
            </div>
            <div class="service-content">
              <h4>Refreshing Design</h4>
              <p>
                Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                eirmod tempor ividunt labor dolore magna.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="service-icon">
              <i class="lni lni-bootstrap"></i>
            </div>
            <div class="service-content">
              <h4>Solid Bootstrap 5</h4>
              <p>
                Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                eirmod tempor ividunt labor dolore magna.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="service-icon">
              <i class="lni lni-shortcode"></i>
            </div>
            <div class="service-content">
              <h4>100+ Components</h4>
              <p>
                Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                eirmod tempor ividunt labor dolore magna.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="service-icon">
              <i class="lni lni-dashboard"></i>
            </div>
            <div class="service-content">
              <h4>Speed Optimized</h4>
              <p>
                Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                eirmod tempor ividunt labor dolore magna.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="service-icon">
              <i class="lni lni-layers"></i>
            </div>
            <div class="service-content">
              <h4>Fully Customizable</h4>
              <p>
                Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                eirmod tempor ividunt labor dolore magna.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="service-icon">
              <i class="lni lni-reload"></i>
            </div>
            <div class="service-content">
              <h4>Regular Updates</h4>
              <p>
                Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                eirmod tempor ividunt labor dolore magna.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  );
};