import React from "react";

export const Header = (props) => {
  return (
    <section id="hero-area" class="header-area header-eight">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="header-content">
            <h1>Corporate & Business Site Template by Ayro UI.</h1>
            <p>
              We are a digital agency that helps brands to achieve their
              business outcomes. We see technology as a tool to create amazing
              things.
            </p>
            <div class="button">
              <a href="javascript:void(0)" class="btn primary-btn">Get Started</a>
              <a href="https://www.youtube.com/watch?v=r44RKWyfcFw&fbclid=IwAR21beSJORalzmzokxDRcGfkZA1AtRTE__l5N4r09HcGS5Y6vOluyouM9EM"
                class="glightbox video-button">
                <span class="btn icon-btn rounded-full">
                  <i class="lni lni-play"></i>
                </span>
                <span class="text">Watch Intro</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <div class="header-image">
            <img src="assets/images/header/hero-image.jpg" alt="#" />
          </div>
        </div>
      </div>
    </div>
    </section>

    
  );
};
